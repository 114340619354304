<template>
  <div class="h-full w-full">
    <v-row v-if="loading" justify="center" align="center" class="h-full w-full">
      <v-col cols="auto">
        <h3 v-if="isRu">Мы готовим для вас форму для регистрации...</h3>
        <h3 v-else>We're preparing sign up form for you...</h3>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-if="!loading && !signedUp" justify="center" class="h-full">
      <v-col cols="11" md="6" class="h-full">
        <v-form v-model="valid" class="h-full d-flex justify-space-between flex-column">
          <v-row>
            <v-col cols="11" md="12">
              <h1 v-if="isEng">Sing up for talent pool in {{ registrationName }}</h1>
              <h1 v-else-if="isRu">Пойте для кадрового резерва в {{ registrationName }}</h1>
              <h1 v-else>Zapisz się do talent pool w {{ registrationName }}</h1>
            </v-col>
            <v-col cols="11" md="12">
              <h3 v-if="isEng">Basic information</h3>
              <h3 v-else-if="isRu">
                Основные информации<br />(Просим заполнить ФИО, как в паспорте на английском языке)
              </h3>
              <h3 v-else>Podstawowe informacje</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="signUpData.firstName"
                :label="isEng ? 'First name*' : isRu ? 'Имя*' : 'Imię*'"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="signUpData.lastName"
                :label="isEng ? 'Last name*' : isRu ? 'Фамилия*' : 'Nazwisko*'"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="signUpData.email"
                :label="isRu ? `электронная почта*` : `Email*`"
                outlined
                dense
                maxlength="254"
                hide-details="auto"
                :rules="[rules.emailValidation, rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="signUpData.phone"
                :label="isEng ? `Phone number` : isRu ? `Номер телефона` : `Numer telefonu`"
                outlined
                dense
                maxlength="31"
                hide-details="auto"
                :rules="[rules.maxPhoneLength]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="signUpData.consent.accepted" :rules="[rules.required]">
                <template #[`label`]>
                  <v-clamp :expanded="talentPoolExpanded" autoresize :max-lines="4" ellipsis="...*">
                    {{ `${signUpData.consent.consent}*` }}
                    <template #[`after`]="{ clamped, expanded }">
                      <v-chip
                        v-if="clamped || expanded"
                        color="#133c55"
                        outlined
                        x-small
                        @click.stop.prevent="talentPoolExpanded = !talentPoolExpanded"
                      >
                        {{ talentPoolExpanded ? 'Minimize' : 'Expand' }}
                      </v-chip>
                    </template>
                  </v-clamp></template
                >
              </v-checkbox>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-btn
                x-large
                :disabled="!valid"
                elevation="0"
                color="primary"
                :loading="signingUp"
                class="ma-3"
                @click="signUp"
                >{{ isEng ? 'Sign up' : isRu ? 'Зарегистрироваться' : 'Zapisz się' }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="isRu" cols="12" align-self="end">
              Ответственность за обработку ваших данных, представленных в процессе данной рекрутации, несет, помимо
              вышеупомянутого работодателя, LightApply Sp. z o.o., который является оператором персональных данных.
              LightApply sp. Z o.o. является совместным оператором ваших данных с целью обработки ваших данных при
              заполнении рекрутационной анкеты, а также с целью поощрения вас к созданию профиля кандидата. Обратите
              внимание, что LightApply не контролирует обработку ваших личных данных агентством по трудоустройству,
              которое является обработчиком данных. Вы имеете право получить от администратора подтверждение того,
              обрабатываются ли ваши персональные данные, и, запросить доступ к ним, исправление, удаление, ограничение
              обработки или передачу данных другому администратору. Вы также имеете право подать жалобу Управлению по
              защите личных данных в Польше. По вопросам обращайтесь к нам по адресу contact@lightapply.com. Более
              подробную информацию можно найти в нашей
              <a href="https://lightapply.com/privacy-policy/">Политике конфиденциальности.</a>
            </v-col>
            <v-col v-else cols="12" class="mt-5" align-self="end">
              Apart from the employer mentioned above, LightApply Sp. z o.o., the data processor is responsible for the
              processing of your data provided in the application form. LightApply sp. z o.o. is co-administrator of
              your data in order to process your data when you fill in the application form as well as to analyze and
              encourage you to set up a candidate account. Please be informed that LightApply has no control over
              processing of your personal data by the recruiting company, the data controller. The data subject has the
              right to obtain from the controller confirmation as to whether or not personal data concerning him or her
              are being processed, and, where that is the case to request access, correction, deletion, restriction of
              processing or transfer data to another controller. You also have the right to object or make a complaint
              to the President of the Personal Data Protection Office in Poland. Should you have any questions or
              requests, please don't hesitate to contact us at contact@lightapply.com For more information, see our
              <a href="https://lightapply.com/privacy-policy/">Privacy Policy.</a>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-if="!loading && signedUp && !signingUp" justify="center" align="center" class="h-full w-full">
      <v-col cols="auto" class="d-flex align-center justify-center flex-column">
        <h1 v-if="isEng">Sing up confirmed</h1>
        <h1 v-else-if="isRu">Подтверждено</h1>
        <h1 v-else>Poprawnie się zapisłeś</h1>
        <h3 v-if="isEng">After registering with your e-mail you can manage your consents.</h3>
        <h3 v-else-if="isRu">После регистрации с помощью электронной почты вы можете управлять своими согласиями.</h3>
        <h3 v-else>Po zalogowaniu przy użyciu e-maila możesz zarządzać swoimi zgodami.</h3>
        <v-btn class="mt-8" elevation="0" color="primary" @click="redirectToCandidatePanel">{{
          isEng ? 'Candidate panel' : isRu ? 'Панель кандидатов' : 'Panel kandydata'
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import VClamp from 'vue-clamp'

import { axiosIns } from '@/plugins/axios'
import router from '@/router'

export default {
  name: 'TalentPoolSignUpForm',
  components: {
    VClamp,
  },
  setup() {
    const { signUpForTalentPool } = useActions('talentPool', ['signUpForTalentPool'])

    const loading = ref(false)
    const valid = ref(false)

    const signUpData = ref({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      invitedBy: router.currentRoute.query.invited_by,
      consent: { accepted: false, consent: '' },
    })

    const registrationName = ref('')
    const signedUp = ref(false)
    const signingUp = ref(false)
    const lang = ref('')

    const talentPoolExpanded = ref(false)

    const isEng = computed(() => lang.value === 'eng')
    const isRu = computed(() => lang.value === 'ru')

    const rules = computed(() => {
      return {
        required: (value) => !!value || (isRu.value ? 'Обязательно' : 'Required'),
        maxPhoneLength: (value) =>
          (value || '').length <= 31 || (isRu.value ? 'Максимум 31 символ' : 'Max 31 characters'),
        emailValidation: (value) =>
          !!value.match(/^$|^[a-zA-Z0-9._%+-.]+@[a-zA-Z0-9]+\.[a-z]+/) ||
          (isRu.value ? 'Электронное письмо неправильно отформатировано' : 'Email is incorrectly formatted'),
      }
    })

    const signUp = async () => {
      if (!valid) return

      signingUp.value = true
      if (await signUpForTalentPool(signUpData.value)) signedUp.value = true
      signingUp.value = false
    }

    const redirectToCandidatePanel = () => {
      if (process.env.VUE_APP_ENV === 'demo') {
        window.location.replace('https://app.demo.lightapply.com')
      } else if (process.env.VUE_APP_ENV === 'staging') {
        window.location.replace('https://app.staging.lightapply.com')
      } else {
        window.location.replace('https://app.lightapply.com')
      }
    }

    onMounted(async () => {
      try {
        loading.value = true
        const { data } = await axiosIns.get('company/talent-pool/sign-up/')
        registrationName.value = data.registrationName
        signUpData.value.consent.consent = data.talentPoolConsent
        lang.value = router.currentRoute.query.lang ?? 'eng'
        loading.value = false
      } catch (e) {
        loading.value = false
      }
    })

    return {
      redirectToCandidatePanel,
      signUp,

      signUpData,
      signedUp,
      signingUp,

      registrationName,
      loading,
      valid,

      talentPoolExpanded,

      isRu,
      isEng,
      rules,
    }
  },
}
</script>

<style scoped></style>
